.vendor-qna-sections-container {
  .search-result-lbl {
    font-size: 25px;
    font-weight: bold;
    margin-top: 20px;
  }

  .ant-collapse-header {
    font-weight: bolder;
  }
  
  .list-sections {
    margin-top: 30px;

    .highlight-word {
      background: #ffec3d;
      font-weight: 600;
      padding: 0;
    }

    .matching-question {
      font-weight: 600;
    }

    .matching-answer {
      margin-left: 2rem;
      font-weight: 400;
    }

    .section-title {
      font-weight: bolder;

      .matching-qna-item:hover {
        cursor: pointer;
        background: #f5f5f5;
      }
    }

    .section-title:hover {
      cursor: pointer;
      background: #f5f5f5;
    }

    .search-item:hover {
      cursor: pointer;
      background: #f5f5f5;
    }
  }
}

.detail-question-modal {

  #content-wrapper {
    img {
      width: 100%;
    }

    .image_resized {
      display: block;
      margin: auto;
    }

    .image-style-side {
      float: right;
    }
  }

  .matching-question {
    font-weight: 600;
    font-size: 18px;
  }

  .matching-answer {
    margin-left: 2rem;
    font-weight: 400;
  }
}
