.confirm-reject-msg {
  margin-top: 20px;
}

.confirm-reject-title {
  font-size: 1.2rem;
  font-weight: bold;
}

.formItem label {
  float: left!important;
}
.formItem label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
content: none!important;
}
.formItem label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
display: inline-block;
margin-right: 4px;
font-size: 14px;
font-family: SimSun, sans-serif;
line-height: 1;
content: '(*) :';
}