.preview-invoice-modal {
  overflow: hidden;

  .preview-invoice-table {
    .updated-invoice-row {
      background: white;
    }

    .new-invoice-row {
      background: #d9f4be;
    }

    .error-cell {
      background: #ffccc7 !important;
    }

    .created-cell {
      background: #d9f4be !important;
    }

    .inherit-cell {
      background: inherit !important;
    }

    .updated-cell {
      background: #fef5b8 !important;
    }
  }

  .header-confirmation-msg {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .incorrect-records-msg {
    margin-top: 10px;
    color: red;
    font-size: 1.2rem;
  }
}

.uploadInvoice-container {
  .action-buttons {
    width: 100%;
    justify-content: flex-end;
    margin-top: 30px;
  }
}
