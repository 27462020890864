#top-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 80px;
  // background-color: aquamarine;
  // opacity: .1;
  .child-div:nth-child(1) {
    // background-color: green;
    width: 50%;
    padding: 25px;
  }
  .child-div:nth-child(2) {
    // background-color: blue;
    padding: 20px;
    display: flex;
    width: 50%;
  }
  .grand-child-div{
    width: 50%;
    
  }
}

#editor {
  margin-top: 1em;
  width: 100%;
  display: flex;
  height: fit-content;
  .child-section {
    padding: 25px;
    width: 50%;
  }

  .child-section:nth-child(1) {
    // background-color: green;
  }
  .child-section:nth-child(2) {
    // background-color: blue;
  }
}
.mt20{
  margin-top: 20px;
}

.header-title{
  margin: 0 10px 10px 0;
  display: inline-block;
}
.myBtnAdd{
  float: right;
  margin: 19px;
  width: 109px;
}

.ellipsis-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}