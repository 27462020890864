.key-email-right-col {
  width: 100%;
  justify-content: space-between;

  div.ant-space-item:first-child {
    flex: 1
  }
}

.attachment-field {
  .ant-form-item-control-input-content > span {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }
}

.loading-container {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}