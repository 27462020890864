.qna-section-container {
  .action-buttons {
    width: 100%;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .active-cell {
    color: black
  }

  .inactive-cell {
    color: #d9d9d9
  }
}

.incorrect-records-msg {
  margin-top: 10px;
  color: red;
  font-size: 1.2rem;
}
