:root {
  --amplify-primary-color: #1890ff;
  --amplify-primary-tint: #40a9ff;
  --amplify-primary-shade: #096dd9;
}

.custom-require-new-password .form-section-header {
  margin: 1rem 0px 1.5rem;
  font-size: 1.15rem;
}

.custom-require-new-password {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-size: 100% 100%;
}

.custom-signin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-size: 100% 100%;
}

.custom-radio-button {
  display: block;
  width: 100%;
  padding: 16px;
  font-size: var(--amplify-text-sm);
  font-family: var(--font-family);
}