.vendor-layout .logo {
  height: 60px;
  /* background: rgba(255, 255, 255, 0.3); */
  background-image: url(../../assets/logo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ant-layout-sider-collapsed .logo {
  height: 42px;
  margin: 10px;
  background-image: url(../../assets/logo_mobi.png);
  background-size: contain;
}

.vendor-layout .site-layout .site-layout-background {
  background: #fff;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color: #fff;
}

.vendor-layout .ant-layout-sider-collapsed .text-overflow-hidden{
  white-space: nowrap; 
  width: 48px; 
  overflow: hidden;
  text-overflow: ellipsis;
}