.view-invoice-table thead.ant-table-thead > tr:first-child > th:last-child {
  display: flex;
  padding: 30px 8px;
}

.view-invoice-table tbody.ant-table-tbody > tr > td:last-child > button:first-child {
  margin-right: 8px;
}

.preview-invoice-modal {
  overflow: hidden;

  .preview-duplicated-invoice-table {
    .updated-invoice-row {
      background: white;
    }

    .new-invoice-row {
      background: #d9f4be;
    }

    .error-cell {
      background: #ffccc7 !important;
    }

    .inherit-cell {
      background: inherit !important;
    }

    .updated-cell {
      background: #fef5b8 !important;
    }
  }

  .header-confirmation-msg {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .incorrect-records-msg {
    margin-top: 10px;
    color: red;
    font-size: 1.2rem;
  }
}

.uploadInvoice-container {
  .action-buttons {
    width: 100%;
    justify-content: flex-end;
    margin-top: 30px;
  }
}
